import { ThemeType } from 'src/types/theme';
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import LibraryService from '@src/services/library/LibraryService';
import { useRouter } from 'next/router';
import { categoryCode2Id } from '@src/lib/public-lib';
import { PageProps } from '@pages/page/[page]';
import { PagePropsContext } from '@src/context/Pages';
import { RootState, useSelector } from '@src/store';

export type PagenationProps = {
    selectedPage: number;
    setSelectedPage: any;
    listNum: number;
    carouselRef?: any;
};

export default function Pagenation(props: PagenationProps)
{
    const router = useRouter();
    const media = useSelector((state: RootState) => state.media);
    const pageProps: PageProps = useContext(PagePropsContext);

    const [ numLength, setNumLength ] = useState(media.isDesktop ? 10 : 5);
    const [ firstNum, setFirstNum ] = useState<number>(Math.floor(Number(router.query.page) / numLength) * numLength + 1);
    const [ pageNums, setPageNums ] = useState<number[]>([1]);
    const [ maxNum, setMaxNum ] = useState(1);
    const [ isLast, setIsLast ] = useState(false);
    const [ ctgName, setCtgName ] = useState("");
    const [ subCdName, setSubCdName ] = useState("");

    const handlePageClick = (pageNumber: number) => {
        props.setSelectedPage(pageNumber);
    };

    const handlePrevClick = () => {
        if (props.selectedPage > 1)
        {
            props.setSelectedPage(props.selectedPage - 1);

            if (props.selectedPage - 1 < pageNums[0])
            {
                setFirstNum(firstNum - numLength);

                if (process.browser)
                    window.scrollTo(0, 150);
            }
        }
    };

    const handleNextClick = () => {
        if (props.selectedPage < maxNum)
        {
            props.setSelectedPage(props.selectedPage + 1);

            if (pageNums[pageNums.length - 1] === maxNum)
                setIsLast(true);

            if (props.selectedPage >= pageNums[pageNums.length - 1])
            {
                // 다음 행의 첫 번째 버튼으로
                setFirstNum(firstNum + numLength);
                setIsLast(false);

                if (process.browser)
                    window.scrollTo(0, 150);
            }
        }
    };

    useEffect(() => {
        let newPages = Array.from({length: numLength}, (_, i) => firstNum + i).filter(num => num <= maxNum);
            setPageNums(newPages);
    }, []);

    useEffect(() => {
        setNumLength(media.isDesktop ? 10 : 5);
    }, [media]);

    useEffect(() => {
        let newPages = Array.from({length: numLength}, (_, i) => firstNum + i).filter(num => num <= maxNum);
        setPageNums(newPages);
    }, [firstNum, numLength]);

    useEffect(() => {
        let current: number = parseInt(router.query.page as string);
        let first: number = Math.floor((current - 1) / numLength) * numLength + 1;
        let last: number = first + numLength;

        if (last > maxNum + 1)
            last = maxNum + 1;

        let newPages = Array.from({length: last - first}, (_, i) => first + i);
        setPageNums(newPages);
    }, [maxNum]);

    useEffect(() => {
        if (props.selectedPage === maxNum)
            setIsLast(true);
        else
            setIsLast(false);
    }, [maxNum, props.selectedPage]);

    useEffect(() => {
        setMaxNum(Math.ceil(pageProps.dataNum / props.listNum));
    }, [pageProps.dataNum]);

    return (
        <Wrapper>
            <PageBtnWrap>
                <PageBtn disable={ props.selectedPage <= 1 } onClick={ handlePrevClick }>{ "<" }</PageBtn>
                { pageNums.length === 0 ?
                    <PageBtn style={{ cursor: "default" }}>1</PageBtn>
                    :
                    pageNums.map((page, index) =>
                        page > 0 &&
                            <PageBtn sellected={ props.selectedPage == page } onClick={() => handlePageClick(page)} key={ "page_" + index } >
                                { page }
                            </PageBtn>
                    )
                }
                <PageBtn disable={ props.selectedPage === 0 || maxNum === 0 || isLast } onClick={ handleNextClick }>{ ">" }</PageBtn>
            </PageBtnWrap>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: fit-content;
    margin: 30px auto 35px;
`;

const PageBtnWrap = styled.div`
    display: flex;
`;

const PageBtn = styled.div<{ sellected?: boolean, disable?: boolean }>`
    padding: 0 10px;
    cursor: ${ props => props.disable ? "default" : "pointer" };
    font-size: 0.2rem;
    font-weight: ${ props => props.sellected ? "bold" : "normal" };
    color: ${ props => props.disable ? "gray" : "white" };
`;