import { IconProps } from "@src/types";

export default function CirclePauseIcon(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 ${width} ${height}` } fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.onClick }>
            <path d="M10.2857 18H15.4286V0H10.2857M0 18H5.14286V0H0V18Z" fill="white" style={{ transform: "translate(14.725px, 13.5px)" }} /> 
            <circle cx="22.5" cy="22.5" r="22" stroke={ fill } />
        </svg> 
    );
}