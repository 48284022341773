import { IconProps } from "@src/types";

export default function PlayIcon(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 15 18` } fill={ fill } xmlns="http://www.w3.org/2000/svg" style={ props.style } onClick={ props.onClick }>
            <path d="M0 0V18L14.1429 9L0 0Z"/> 
        </svg> 
    );
}