import axios, { AxiosError, AxiosResponse } from "axios";
import { fileDataConfig, imageUploadConfig, itemDownloadConfig } from "./config";

async function fileData(data: { fileId: string })
{
	let res = await axios(fileDataConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError) => {
			throw e.message;
		});

	return res;
}

async function imageUpload(file: { file: any })
{
	let res = await axios(imageUploadConfig(file))
		.catch((e: AxiosError) => {
            console.log(e);
		});

	return res;
}

async function downloadItem(data: { fileId: string })
{
	let res = await axios(itemDownloadConfig(data))
		.catch((e: AxiosError) => {
            console.log(e);
		});

	return res;
}

async function getMusicSource(data: { fileId: string })
{
	let res = await axios(itemDownloadConfig(data))
		.catch((e: AxiosError) => {
            console.log(e);
		});

	return res;
}

const FileService = {
    fileData,
    imageUpload,
	downloadItem,
	getMusicSource,
};

export default FileService;