import { IconProps } from "@src/types";

export default function Download(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 25 25` } fill={ fill } xmlns="http://www.w3.org/2000/svg" style={ props.style } onClick={ props.onClick }>
            <path d="M5 20H19V18H5M19 9H15V3H9V9H5L12 16L19 9Z" fill="white"/>
        </svg> 
    );
}