import { IconProps } from "@src/types";

export default function PauseIcon(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 15 18` } fill={ fill } xmlns="http://www.w3.org/2000/svg" style={ props.style } onClick={ props.onClick }>
            <path d="M10.2857 18H15.4286V0H10.2857M0 18H5.14286V0H0V18Z" fill="white"/> 
        </svg> 
    );
}