import { PageProps } from "@pages/page/[page]";
import { PayProps } from "@pages/pay";
import { BoardProps } from "@src/types/props";
import { MainProps } from "pages";
import { createContext } from "react";

let defaultPageProps: PageProps = {
    page: 0,
    mainData: [],
    categoryData: [],
    tagData: "",
    dataNum: 0
};

let defaultBoardProps: BoardProps = {
    datas: [],
};

let defaultPayProps: PayProps = {
    payList: [],
};

export const PagePropsContext = createContext<PageProps>(defaultPageProps);
export const BoardPropsContext = createContext<BoardProps>(defaultBoardProps);
export const PayPropsContext = createContext<PayProps>(defaultPayProps);