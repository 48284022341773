import React from 'react';
import Head from 'next/head';

export type MetaTagsProps = {
	title?: string;
	siteTitle?: string;
	siteDescription?: string;
	ogImage?: string;
	pageUrl?: string;
};

function MetaTags(mataProps: MetaTagsProps)
{
	const viewSiteTitle = mataProps.siteTitle ? ' | ' + mataProps.siteTitle : '';
	const pageTitle = mataProps.title ? mataProps.title : 'YULBGM' + viewSiteTitle;
	const ogImgUrl = mataProps.ogImage ? mataProps.ogImage : '/images/yullogo.png';
	const ogTitle = mataProps.title ? mataProps.title : 'YULBGM' + viewSiteTitle;

	return (
		<Head>
			<title>{pageTitle}</title>
			<meta property="og:title" content={ogTitle} />
			<meta property="og:image" content={ogImgUrl} />
			<meta property="og:image:width" content="400" />
			<meta property="og:image:height" content="300" />
			{mataProps.siteDescription && (
				<>
					<meta name="description" content={mataProps.siteDescription} />
					<meta property="og:description" content={mataProps.siteDescription} />
				</>
			)}
			{mataProps.pageUrl && (
				<>
					<meta property="og:url" content={mataProps.pageUrl} />
					<link rel="canonical" href={mataProps.pageUrl} />
				</>
			)}
		</Head>
	);
}

export default React.memo(MetaTags);