import { IconProps } from "@src/types";

export default function NextIcon(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 12 12` } fill={ fill } xmlns="http://www.w3.org/2000/svg" style={ props.style } onClick={ props.onClick }>
            <path d="M10 12H12V0H10M0 12L8.5 6L0 0V12Z" /> 
        </svg> 
    );
}