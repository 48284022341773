import { Button, P } from "@src/components/atoms";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ThemeType } from "@src/types/theme";
import { t_library } from "@src/types/tables";
import { BiTimeFive } from "react-icons/bi";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";
import { addCart, deleteCart, stripHtmlTags } from "@src/lib/public-lib";
import { MdShoppingCart, MdOutlineShoppingCart } from "react-icons/md";
import { getCookie } from "@src/lib/util/cookie";
import Router, { useRouter } from "next/router";
import FileService from "@src/services/file/FileService";
import { AiOutlineCheckCircle } from "react-icons/ai";
import CirclePlayIcon from "@src/components/icons/player/play/CirclePlayIcon";
import Download from "@src/components/icons/download/Download";
import Cart from "@src/components/icons/cart/Cart";
import CirclePauseIcon from "@src/components/icons/player/play/CirclePauseIcon";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export type ListItemProps = {
    data: t_library;
    index: number;
    style?: React.CSSProperties;
    isPurchase: boolean;
    isInCart: boolean;
    setStateChange: any;
    handleItemPlay: any;
    handleItemPause: any;
    play: boolean;
    playId: string;
    setPlayId: any;
    divRef: any;
    listType: "library" | "purchase";
};

export default function ListItem(props: ListItemProps)
{
    const { t } = useTranslation("common");
    const media = useSelector((state: RootState) => state.media);
    const ref = useRef<HTMLDivElement>(null);

    const [ isPlay, setIsPlay ] = useState(false);
    const [ time, setTime ] = useState("");
    const [ tags, setTags ] = useState<string[]>([]);
    const [ isSmall, setIsSmall ] = useState(false);
    const [ iconSize, setIconSize ] = useState(25);
    const [ subject, setSubject ] = useState("");
    const [ subjectCd, setSubjectCd ] = useState("");

    const downloadRef = useRef<any>();
    const router = useRouter();

    function play()
    {
        props.handleItemPlay(props.data);
        props.setPlayId(props.data.id);
		setIsPlay(true);
	};

    function pause()
    {
        props.handleItemPause();
        setIsPlay(false);
    };

    async function onAddCart()
    {
        if (getCookie("accessToken"))
        {
            await addCart(props.data.id);
            props.setStateChange("add");
            alert("카트에 추가되었습니다.");
        }
        else
        {
            Router.push("/login")
            alert("로그인이 필요합니다.");
        }
    };

    async function onRemoveCart()
    {
        if (getCookie("accessToken"))
        {
            await deleteCart(props.data.id);
            props.setStateChange("remove");
            alert("카트에서 제거되었습니다.");
        }
        else
        {
            Router.push("/login")
            alert("로그인이 필요합니다.");
        }
    };

    function handleDownload(fileType: string)
    {
        if (getCookie("accessToken") && props.isPurchase)
        {
            let fileId: string = fileType === "mp3" ? props.data.mp3FileId : props.data.wavFileId;
            let memeType: string = fileType === "mp3" ? "audio/mpeg" : "audio/wav";

            FileService.downloadItem({ fileId: fileId })
            .then(res => {
                let uint8Array = new Uint8Array((res as any).data);
                const blob = new Blob([uint8Array], { type: memeType });
                let url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.download = props.data.title;
                link.href = url;
                link.click();

                // downloadRef.current.download = null;
                window.URL.revokeObjectURL(url);
            });
        }
        else
        {
            if (!getCookie("accessToken"))
                alert("로그인이 필요합니다.");
            else if (!props.isPurchase)
            {
                alert("구매하셔야 다운로드 하실 수 있습니다.");

                let checkedList: string[] = [ props.data.id ];
                let json: string = JSON.stringify(checkedList);

                router.push({ pathname: "/pay",  query: { data: json } }, "/pay");
            }
        }
    };

    useEffect(() => {
        const qeury = router.query;

        if (qeury.id)
        {
            if (qeury.id === "LIB00001")
            {
                setSubject("콘텐츠 형태");
                setSubjectCd("");
            }
            else if (qeury.id === "LIB00002")
            {
                setSubject("장르");
                setSubjectCd(props.data.genre)
            }
            else if (qeury.id === "LIB00003")
            {
                setSubject("분위기");
                setSubjectCd(props.data.mood);
            }
            else if (qeury.id === "LIB00004")
            {
                setSubject("테마");
                setSubjectCd(props.data.theme);
            }
            else if (qeury.id === "LIB00005")
            {
                setSubject("장면");
                setSubjectCd(props.data.scene);
            }
            else if (qeury.id === "LIB00006")
            {
                setSubject("소리효과");
                setSubjectCd(props.data.SOUND_EFFECT_CD);
            }
            else if (qeury.id === "LIB00007")
            {
                setSubject("배경음");
                setSubjectCd(props.data.bgm);
            }
            else if (qeury.id === "LIB00009")
            {
                setSubject("악기");
                setSubjectCd(props.data.instrument);
            }
            else if (qeury.id === "LIB00010")
            {
                setSubject("국가");
                setSubjectCd(props.data.nation);
            }
        }
    }, [router, props.data]);

    // 데이터가 들어왔을 때
    useEffect(() => {
        if (props.data.tags)
        {
            let _tags = props.data.tags.split(",");

            _tags.forEach((tag, idx) => {
                _tags[idx] = tag.trimStart().trimEnd();
            });

            setTags(_tags);
        }
    }, [props.data, props.isPurchase]);

    useEffect(() => {
        if (media.isDesktop)
            setIsSmall(false);
        
        if (media.isTablet)
        {
            if (media.deviceLevel === "smallTablet")
                setIsSmall(true);
            else
                setIsSmall(false);
        }

        if (media.isMobile)
            setIsSmall(true);
    }, [media]);

    useEffect(() => {
        if (props.data.id === props.playId)
            setIsPlay(props.play);
    }, [props.play]);

    useEffect(() => {
        if (props.data.id === props.playId)
            setIsPlay(true);
        else
            setIsPlay(false);
    }, [props.playId]);

    const PurchaseBtns = () => {
        return (
            <DownLoadWrapper>
                <DownLoadButton onClick={ () => handleDownload("wav") }>
                    <p>WAV</p>
                    <Download width={ 25 } style={{ cursor: "pointer" }} height={ 25 } />
                </DownLoadButton>
                <DownLoadButton onClick={ () => handleDownload("mp3") }>
                    <p>MP3</p>
                    <Download width={ 25 } style={{ cursor: "pointer" }} height={ 25 } />
                </DownLoadButton>
            </DownLoadWrapper>
        );
    };

    return (
        <>
            <Wrapper index={ props.index } ref={ props.divRef }>
                <ItemLine>
                    <PlayButton>
                        { isPlay ?
                            <CirclePauseIcon width={ 45 } height={ 45 } fill="white" onClick={ pause } />
                            :
                            <CirclePlayIcon width={ 45 } height={ 45 } fill="white" onClick={ play } />
                        }
                    </PlayButton>
                    <div style={ media.isMobile ? { width: "100%" } : { width: "100%", display: "flex" } }>
                        <TitleWrapper>
                            <Link href={{ pathname: `/detail/${props.data.title}`, query: { id: props.data.id } }}>
                                <ItemTitle>
                                    { `${props.data.title}` }
                                </ItemTitle>
                            </Link>
                            <ArtistText>{ "by YULCOMPANY" }</ArtistText>
                            { media.isMobile && props.listType === "purchase" &&
                                <PurchaseBtns />
                            }
                            <SmallTextNormal mt={ 10 }>{ stripHtmlTags(props.data.desc) }</SmallTextNormal>
                        </TitleWrapper>
                        {/* { !media.isMobile && subject &&
                            <SubjectWrapper>
                                <TitleItemText>{ subject }</TitleItemText>
                                <SmallText>{ subjectCd }</SmallText>
                            </SubjectWrapper>
                        } */}
                        { (!media.isMobile || props.listType === "purchase") && 
                            <InfoWrapper>
                                <TimeWrapper>
                                    { time &&
                                        <BiTimeFive size={ 20 } style={{ paddingTop: "5px" }} />
                                    }
                                    <SmallTextBold style={{ minWidth: "45px", margin: "auto 0 auto 5px" }}>{ time }</SmallTextBold>
                                </TimeWrapper>
                                <SmallTextBold>{ props.data.genre }</SmallTextBold>
                            </InfoWrapper>
                        }
                        {/* { !media.isMobile &&
                            <Price price={ props.data.price } discount={ props.data.discountRate } />
                        } */}
                        <IconButtonWrap>
                            { props.listType === "library" ?
                                <>
                                    { props.isPurchase ?
                                        <>
                                            <AiOutlineCheckCircle style={{ marginRight: "15px" }} size={ iconSize } color="gray" />
                                            <PurchaseBtns />
                                        </>
                                        :
                                        <>
                                            { getCookie("accessToken") && props.isInCart ?
                                                <MdShoppingCart style={{ marginRight: "15px", cursor: "pointer" }} size={ iconSize } onClick={ onRemoveCart } />
                                                :
                                                <Cart width={ 25 } height={ 25 } fill="white" style={{ marginRight: "15px", cursor: "pointer" }} onClick={ onAddCart } />
                                            }
                                            <Download width={ 25 } height={ 25 } style={{ cursor: "pointer"}} onClick={ () => { handleDownload("") }} />
                                        </>
                                    }
                                    
                                </>
                                :
                                <>
                                    { !media.isMobile &&
                                        <PurchaseBtns />
                                    }
                                </>
                            }
                        </IconButtonWrap>
                    </div>
                </ItemLine>
            </Wrapper>
            { props.listType === "purchase" &&
                <TagWrapper>
                    <TagCategory>
                        <SmallTextNormal style={{ color: "#999" }}>분위기</SmallTextNormal>
                        <div style={{ display: "flex" }}>
                            { props.data.mood.split(',').map((tag: string, index: number) =>
                                <TagBox key={ `tag_mood_${index}` }>
                                    <Tag>{ tag }</Tag>
                                </TagBox>
                            )}
                        </div>
                    </TagCategory>
                    { media.isMobile ?
                        <>
                            <TagCategory>
                                <SmallTextNormal style={{ color: "#999" }}>장면</SmallTextNormal>
                                <div style={{ display: "flex" }}>
                                    { props.data.scene.split(',').map((tag: string, index: number) =>
                                        <TagBox key={ `tag_scene_${index}` }>
                                            <Tag>{ tag }</Tag>
                                        </TagBox>
                                    )}
                                </div>
                            </TagCategory>
                            <TagCategory>
                                <SmallTextNormal style={{ color: "#999" }}>테마</SmallTextNormal>
                                <div style={{ display: "flex" }}>
                                    { props.data.theme.split(',').map((tag: string, index: number) =>
                                        <TagBox key={ `tag_theme_${index}` }>
                                            <Tag>{ tag }</Tag>
                                        </TagBox>
                                    )}
                                </div>
                            </TagCategory>
                        </>
                        :
                        <TagCategory>
                            <SmallTextNormal style={{ color: "#999" }}>장면, 테마</SmallTextNormal>
                            <div style={{ display: "flex" }}>
                                { props.data.scene.split(',').map((tag: string, index: number) =>
                                    <TagBox key={ `tag_scene_${index}` }>
                                        <Tag>{ tag }</Tag>
                                    </TagBox>
                                )}
                                { props.data.theme.split(',').map((tag: string, index: number) =>
                                    <TagBox key={ `tag_theme_${index}` }>
                                        <Tag>{ tag }</Tag>
                                    </TagBox>
                                )}
                            </div>
                        </TagCategory>
                    }
                </TagWrapper>
            }           
        </>
    );
}

const Wrapper = styled.div<{ index: number }>`
    height: fit-content;
    margin-top: ${props => props.index === 0 && 0};
    padding: 24px 0;
    top: -175px;
    border-top: solid ${props => props.index === 0 ? 0 : "1px"} #373737;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        padding-right: 20px;
    `};
`;

const ItemLine = styled.div`
    width: 100%;
    display: flex;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        position: relative;
    `};
`;

const PlayButton = styled(Button)`
    margin-right: 20px;
    color: ${({ theme }: { theme: ThemeType }) => theme.contentDark};
`;

const TitleWrapper = styled.div`
    width: 100%;
    flex: 4.5;
    margin-right: 20px;

    ${({ theme }: { theme: ThemeType }) => theme.largeTablet`
        margin: 0;
    `};
`;

const ItemTitle = styled(P)`
    color: #F3B25A;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;

    ${({ theme }: { theme: ThemeType }) => theme.largeTablet`
        font-size: 16px;
    `};
`;

const ArtistText = styled(P)`
    color: #999;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 700;
    line-height: 2.5;
`;

const InfoWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex: 1.8;

    ${({ theme }: { theme: ThemeType }) => theme.largeTablet`
        flex: 0;
        display: none;
    `};
`;

const IconButtonWrap = styled.div`
    margin-top: 20px;
    justify-content: flex-end;
    display: flex;
    flex: 1.4;
    padding-left: 15px;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        position: absolute;
        top: -15px;
        right: 0;
    `};
`;

const SmallTextNormal = styled(P)`
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
`;

const SmallTextBold = styled(SmallTextNormal)`
    font-weight: 700;
`;

const TimeWrapper = styled.div`
    width: fit-content;
    display: flex;
`;

const TagWrapper = styled.div`
    display: flex;
    padding-left: 65px;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        width: fit-content;
        display: block;
    `};
`;

const TagCategory = styled.div`
    max-width: 350px;
    margin: 0 80px 19px 0;

    p {
        padding-bottom: 12px;
    };
`;

const TagBox = styled.div`
    width: fit-content;
    height: 34px;
    min-width: 80px;
    padding: 0 20px;
    margin: 0 15px 5px 0;
    border: 1px solid #999;
    border-radius: 100px;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        font-size: 0.15rem;
	`};
`;

const Tag = styled(P)`
    width: fit-content;
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 700;
    line-height: 32px; 
    white-space: nowrap;
    margin: 0 auto;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        font-size: 0.15rem;
	`};
`;

const DownLoadWrapper = styled.div`
    width: 150px;
    display: flex; 
    justify-content: space-between;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        margin: 20px 0;
	`};
`;

const DownLoadButton = styled.div`
    display: flex;
    cursor: pointer;

    p {
        margin-right: 8px;
        color: #FFF;
        text-align: right;
        font-size: 14px;
        font-weight: 700;
        line-height: 25px; 
    };
`;