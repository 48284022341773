import { IconProps } from "@src/types";

export default function PrevIcon(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 12 12` } fill={ fill } xmlns="http://www.w3.org/2000/svg" style={ props.style } onClick={ props.onClick }>
            <path d="M2 -8.74228e-07L1.04907e-06 -1.04907e-06L0 12L2 12M12 0L3.5 6L12 12L12 0Z"/>
        </svg> 
    );
}