import { IncomingMessage } from 'http';
const qs = require('qs');

import { baseConfig } from '../api';

export const fileDataConfig = (data: { fileId: string }) => 
	baseConfig().post('/api/file/getFileData', qs.stringify(data));

export const imageUploadConfig = (file: { file: any }) => 
	baseConfig().put('/api/file/uploadFile', qs.stringify(file));

export const itemDownloadConfig = (data: { fileId: string }) => 
	baseConfig().post('/api/file/downloadItem', qs.stringify(data), { responseType: "arraybuffer" });

export const musicUrlConfig = (data: { fileId: string }) => 
	baseConfig().post('/api/file/getMusicUrl', qs.stringify(data), { responseType: "arraybuffer" });