import Header from "@src/components/common/header/Header";
import { ThemeType } from "@src/types/theme";
import dynamic from "next/dynamic";
import { useRef } from "react";
import Footer from "src/components/common/footer/Footer";
import MetaTags, { MetaTagsProps } from "src/lib/meta/MetaTags";
import styled from "styled-components"

export type CommonLayoutProps = {
	children: any;
	disableHeaderScroll?: boolean;
	hasBottomButton?: boolean;
	isBackHeader?: boolean;
	withoutNavigationBar?: boolean;
    onlyHeader?: boolean
    id?: string;
} & MetaTagsProps;

// export const headerRef = useRef<HTMLDivElement>();
// export const footerRef = useRef<HTMLDivElement>();

export default function CommmonLayout(props: CommonLayoutProps)
{
    // const footerRef = useRef<HTMLDivElement>();

    return (
        <>
            <CommonWrapper>
                <MetaTags { ...props } />
                <Header disableScroll={ props.disableHeaderScroll } isBack={ props.isBackHeader } />
                { props.children }
            </CommonWrapper>
            { !props.onlyHeader &&
                <Footer />
            }
        </>
    )
}

const CommonWrapper = styled.div`
    height: 100%;
    min-height: fit-content;
    /* padding-bottom: 100px; */
    /* background: linear-gradient(#660033, ${({ theme }: { theme: ThemeType }) => theme.body} 500px); */
`;