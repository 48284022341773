import React, { createRef, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ThemeType } from '@src/types/theme';
import WaveLoading from '../card/product/loading/WaveLoading';
import { darkTheme, lightTheme } from '@src/theme/default';
import { addCart, cvtNum2Time, deleteCart } from '@src/lib/public-lib';
import Router from 'next/router';
import { MdShoppingCart } from "react-icons/md";
import cssMixin from '@src/styles';
import PlayIcon from '@src/components/icons/player/play/PlayIcon';
import PrevIcon from '@src/components/icons/player/play/PrevIcon';
import NextIcon from '@src/components/icons/player/play/NextIcon';
import { t_library } from '@src/types/tables';
import { P } from '@src/components/atoms';
import Cart from '@src/components/icons/cart/Cart';
import Download from '@src/components/icons/download/Download';
import { getCookie } from '@src/lib/util/cookie';
import PauseIcon from '@src/components/icons/player/play/PauseIcon';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import WaveSurfer from 'wavesurfer.js';
import { useTranslation } from 'next-i18next';

export type PlayerProps = {
    data: t_library;
    playerRef: React.RefObject<HTMLDivElement>; 
    srcUrl: string;
    setTime?: any;
    waveRef: any;
    audioRef: any;
    color?: string;
    play: boolean;
    setPlay: any;
    musicChange: boolean;
    setMusicChange: any;
    isPurchase: boolean;
    isInCart: boolean;
    setStateChange: any;
    handlePrevClick: any;
    handleNextClick: any;
};

export default function Player(props: PlayerProps)
{
    const { t } = useTranslation("common");
    const media = useSelector((state: RootState) => state.media);
    const subWrapRef = useRef<HTMLDivElement>(null);
    const iconBtnWrapRef = useRef<HTMLDivElement>(null);

    const [ isReady, setIsReady ] = useState(false);
    const [ waveform, setWaveform ] = useState<WaveSurfer>();
    const [ mouseDown, setMouseDown ] = useState(false);
    const [ waveHeight, setWaveHeight ] = useState(0);

    Router.events.on("beforeHistoryChange", () => {
        if (waveform)
        {
            waveform.destroy();

            if (props.setTime)
                props.setTime(0);
        }
    });    

    const create = async () => {
        const WaveSurfer = (await import("wavesurfer.js")).default;

        if (props.waveRef.current)
        {
            if (waveform)
            {
                waveform.options.url = props.srcUrl;
                waveform.load(props.srcUrl);
            }
            else
            {
                let newWaveform = WaveSurfer.create({
                    container: props.waveRef.current,
                    media: props.audioRef.current,
                    url: props.srcUrl,
                    height: waveHeight,
                    cursorWidth: 1,
                    // barWidth: 2,
                    // barGap: 2,
                    barRadius: 1,
                    progressColor: "#399aec",
                    waveColor: "white",
                    cursorColor: 'transparent',
                    hideScrollbar: true,
                    // barAlign: "bottom",
                });

                let audio = props.audioRef.current as HTMLMediaElement;

                if (props.setTime)
                {
                    audio.addEventListener("loadedmetadata", (e: any) => {
                        let total: number = Math.floor(e.target.duration);
                        props.setTime(cvtNum2Time(total));
                    });
                }

                setWaveform(newWaveform);

                newWaveform.once('interaction', () => {
                    newWaveform.play()
                })
                  
            }
        }
    };

    const handleAddCart = async () => {
        if (getCookie("accessToken"))
        {
            await addCart(props.data.id);
            props.setStateChange("add");
        }
        else
        {
            Router.push("/login")
            alert("로그인이 필요합니다.");
        }
    };

    const handleRemoveCart = async () => {
        if (getCookie("accessToken"))
        {
            await deleteCart(props.data.id);
            props.setStateChange("remove");
        }
        else
        {
            Router.push("/login")
            alert("로그인이 필요합니다.");
        }
    };

    useEffect(() => {
        if (props.srcUrl)
            create();
    }, [props.srcUrl]);

    useEffect(() => {
        if (waveform && props.musicChange)
        {
            waveform.destroy();
            props.setMusicChange(false);
        }
    }, [props.musicChange]);

    useEffect(() => {
        if (media.isDesktop)
        {
            setWaveHeight(50);
            subWrapRef.current!.insertBefore(props.waveRef.current, iconBtnWrapRef.current);
        }

        if (media.isTablet)
        {
            setWaveHeight(32);
            props.playerRef.current?.prepend(props.waveRef.current);
        }

        if (media.isMobile)
        {
            setWaveHeight(32);
            props.playerRef.current?.prepend(props.waveRef.current);
        }
    }, [media]);

    useEffect(() => {
        // if (waveform)
            // waveform.setHeight(waveHeight);
    }, [waveHeight]);

    return (
        <Wrapper ref={ props.playerRef }>
            <SubWrapper ref={ subWrapRef }>
                {/* <WaveLoading progress={cnt} /> */}
                <IconWrapper>
                    { !media.isMobile && <PrevIcon style={{ cursor: "pointer" }} width="12px" height="12px" fill="white" onClick={ props.handlePrevClick } /> }
                    { props.play ?
                        <PauseIcon style={{ cursor: "pointer" }} width="17px" height="20px" fill="white" onClick={ () => props.setPlay(false) } />
                        :
                        <PlayIcon style={{ cursor: "pointer" }} width="16px" height="20px" fill="white" onClick={ () => props.setPlay(true) } />
                    }
                    { !media.isMobile && <NextIcon style={{ cursor: "pointer" }} width="12px" height="12px" fill="white" onClick={ props.handleNextClick } /> }
                </IconWrapper>
                <InfoWrapper>
                    <StyledP1 fontFamily="Pretendard" fontWeight="bold" color="white">
                        { props.data.title }
                    </StyledP1>
                    <StyledP2 pxSize={ 14 } fontFamily="Pretendard" fontWeight="bold" color="white">
                        { "by YULCOMPANY" }
                    </StyledP2>
                </InfoWrapper>
                { <audio ref={ props.audioRef } src={ props.srcUrl } /> }
                <Wave ref={ props.waveRef } visible={ isReady } />
                <IconButtonWrap ref={ iconBtnWrapRef }>
                    { getCookie("isLoggedIn") && props.isInCart ?
                        <MdShoppingCart style={{ marginRight: "15px", cursor: "pointer" }} width={ 25 } height={ 25 } color="gray" onClick={ handleRemoveCart } />
                        :
                        <Cart style={{ marginRight: "15px", cursor: "pointer" }} width={ 25 } height={ 25 } fill="white" onClick={ handleAddCart } />
                    }
                    <Download style={{ cursor: "pointer" }} width={ 25 } height={ 25 } />
                </IconButtonWrap>
            </SubWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div<{ height?: string }>`
    /* ${ cssMixin.container }; */
    width: 100vw;
    height: 90px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #09031D; 

    ${({ theme }: { theme: ThemeType }) => theme.xlargeTablet`
		height: 146px;
	`};
`;

const SubWrapper = styled.div`
    ${ cssMixin.container };
    display: flex;
    padding: 20px 30px 20px 40px;
    align-items: center;

    ${({ theme }: { theme: ThemeType }) => theme.mobile!`
		padding: 20px 20px 20px 35px;
	`};
`;

const IconWrapper = styled.div`
    min-width: 120px;
    max-width: 120px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-right: 40px;

    ${({ theme }: { theme: ThemeType }) => theme.smallTablet!`
        width: fit-content;
		min-width: 0;
        flex: none;
	`};
`;

const InfoWrapper = styled.div`
    /* max-width: 400px; */
    flex: 3;
    user-select: none;

    p {
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 40px;
    };
`;

const StyledP1 = styled(P)`
    font-size: 20px;

    ${({ theme }: { theme: ThemeType }) => theme.largeTablet`
        font-size: 16px;
	`};
`;

const StyledP2 = styled(P)`
    font-size: 14px;
`;

const Wave = styled.div<{ visible: boolean }>`
    width: 100%;
    height: 100%;
    flex: 5;

    wave {
        color: ${({ theme }: { theme: ThemeType }) => theme.contentDark};
    };

    ${({ theme }: { theme: ThemeType }) => theme.xlargeTablet!`
		height: 52px;
        padding: 10px 10px;
        border: 1px solid #373737;
	`};
`;

const IconButtonWrap = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    flex: 0.8;
    padding-left: 15px;
`;