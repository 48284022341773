import { IconProps } from "@src/types";

export default function CirclePlayIcon(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 ${width} ${height}` } fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.onClick }>
            <path d="M18 14V32L32.1429 23L18 14Z" fill={ fill } />
            <circle cx="22.5" cy="22.5" r="22" stroke={ fill } />
        </svg> 
    );
}