import FileService from "@src/services/file/FileService";
import { t_library } from "@src/types/tables";

function playPrev(dataIdx: number, prevData: t_library, handlePlay: any, musicUrl: string, boundingRect: DOMRect)
{
    handlePlay(prevData);
    URL.revokeObjectURL(musicUrl);

    if (boundingRect.top < 75)
    {
        if (dataIdx > 1)
        {
            window.scroll({
                left: 0,
                top: window.scrollY + boundingRect.top - 75,
                behavior: "smooth"
            });
        }
        else
        {
            window.scroll({
                left: 0,
                top: 0,
                behavior: "smooth"
            });
        }
    }
};

function playNext(dataIdx: number, prevData: t_library, handlePlay: any, musicUrl: string, boundingRect: DOMRect, playerRect: DOMRect)
{
    handlePlay(prevData);
    URL.revokeObjectURL(musicUrl);

    let screenHeight: number = window.innerHeight - playerRect.height;

    if (boundingRect.bottom > screenHeight)
    {
        window.scroll({
            left: 0,
            top: window.scrollY - (screenHeight - boundingRect.bottom),
            behavior: "smooth"
        });
    }
};

function loadFile(fileId: string, setMusicUrl: any)
{
    FileService.getMusicSource({ fileId: fileId })
    .then(res => {
        if (res)
        {
            let uint8Array =  new Uint8Array((res as any).data);
            const blob = new Blob([uint8Array], { type: 'audio/mpeg' });
            let url = URL.createObjectURL(blob);

            setMusicUrl(url);
        }
    });
}

export const playerLib = { playPrev, playNext, loadFile }